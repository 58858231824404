import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from 'gatsby-plugin-image';
import { get } from "lodash";

import Layout from "@components/layout";
import SEO from "@components/seo";
const parseUrl = (url) => {
  // if url contains "https://cms.wilderandweathered.com/", replace it with "https://www.wilderandweathered.com/"
  let newUrl = url;
  if (url.includes("https://cms.wilderandweathered.com/")) {
    newUrl = url.replace("https://cms.wilderandweathered.com/", "/");
  }
  if (url.includes("https://wilderandweathered.com/")) {
    newUrl = url.replace("https://wilderandweathered.com/", "/");
  }
  return newUrl;
}
export default function Links({data}) {
  const links = get(data, "prismicFeaturedLinks.data.links", null);
  const bg_image = get(data, "prismicFeaturedLinks.data.bg_image", null);
  console.log(bg_image);
  return (
    <Layout baseRoute="links">
      <SEO title="Featured Links" />
      <div className="bg-image">
        <GatsbyImage
          alt={'Background image'}
          image={bg_image.gatsbyImageData}
          className=""
        />
      </div>
      <div id="links-wrapper">
        <h1>Featured Links</h1>
        {links &&
          links.map((el, index) =>
            el.custom_label.text !== "" ? (
              <a
                className="link"
                key={index}
                href={parseUrl(el.url.url)}
                alt={el.custom_label.text}
                title={el.custom_label.text}
              >
                {el.custom_label.text}
              </a>
            ) : (
              <a
                className="link"
                key={index}
                href={parseUrl(el.url.url)}
                alt={get(el, "title.raw[0].text", "")}
                title={get(el, "title.raw[0].text", "")}
              >
                {get(el, "title.raw[0].text", "link")}
              </a>
            )
          )}
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    prismicFeaturedLinks {
      data {
        bg_image: background_image {
          gatsbyImageData(width: 1920, layout: FULL_WIDTH, placeholder: BLURRED)
        }
        links {
          active
          short_description {
            html
            raw
          }
          title {
            raw
            html
            richText
            text
          }
          url {
            url
          }
          custom_label {
            raw
            richText
            html
            text
          }
        }
      }
    }
  }
`;
